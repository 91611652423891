import React from 'react'
import { Link, graphql } from 'gatsby';
import { Carousel, Layout, SEO, Testimonial } from '../components';

export const query = graphql`
  query {
    normalDay: file(absolutePath: { regex: "/normal_day.svg/" }) { publicURL },
    lightBulb: file(absolutePath: { regex: "/light_bulb.svg/" }) { publicURL },
    call: file(absolutePath: { regex: "/call.svg/" }) { publicURL },
    emergencyGlazing: file(absolutePath: { regex: "/glass.jpg/" }) { childImageSharp { fluid { ...GatsbyImageSharpFluid_withWebp }}},
    upvc: file(absolutePath: { regex: "/upvc.jpg/" }) { childImageSharp { fluid { ...GatsbyImageSharpFluid_withWebp }}},
    interiorDecorating: file(absolutePath: { regex: "/interior-decorating.jpg/" }) { childImageSharp { fluid { ...GatsbyImageSharpFluid_withWebp }}},
    plastering: file(absolutePath: { regex: "/plaster-1.jpg/" }) { childImageSharp { fluid { ...GatsbyImageSharpFluid_withWebp }}},
    floodRestoration: file(absolutePath: { regex: "/plaster.jpg/" }) { childImageSharp { fluid(maxWidth: 1080, cropFocus: SOUTH) { ...GatsbyImageSharpFluid_withWebp }}},
    newBuilds: file(absolutePath: { regex: "/kitchen.jpg/" }) { childImageSharp { fluid { ...GatsbyImageSharpFluid_withWebp }} }
  }
`;

const Home = ({data}) => {
  const slideData = [
    {title: 'Double Glazing', button: 'See Our Glazing Services', image: data.upvc, link: '/services/glazing-services', alt: 'Double Glazing installation recently completed'},
    {title: 'Decorating Services', button: 'Take A Look', image: data.interiorDecorating, link: '/services/painting-and-decorating', alt: 'Newly painted and decorated room'},
    {title: 'Plastering', button: 'See What We Can Do', image: data.plastering, link: '/services/plastering-and-rendering', alt: 'Hole in the roof that is to be plastered'},
    {title: 'Flooding Restoration', button: 'Talk To Us', image: data.floodRestoration, link: '/services/general-maintenance', alt: 'A room that is still to be plastered'},
    {title: 'New Builds', button: 'See More Information', image: data.newBuilds, link: '/services/general-maintenance', alt: 'A newly decorated kitchen with new windows and doors'}
  ]

  return (
    <>
      <SEO />

      <Layout headerBreakpoint={330}>

        <Carousel slideData={slideData} />

        <section>
          <h1>G Parkinson Maintenance</h1>

          <h2>A local family business</h2>

          <p>
            We're a Leicester family-run business that has been built on years of experience, reliablity and quality of workmanship.
            <br />
            Not only are we providing services for residential projects, but for many commercial projects too.
          </p>

          <img src={data.normalDay.publicURL} alt="A generic sunny day illustration" />

          <Link to="/about" className="button secondary">Read Our Story</Link>
        </section>

        <section variant="grey">
          <h1>Our Services</h1>

          <h2>More than just Double Glazing</h2>

          <p>It's easy to think that we're just Decorators or Glaziers; but, despite these being our most popular services, we truly offer much more.</p>

          <img src={data.lightBulb.publicURL} alt="Illustration of a lightbulb that represents new ideas" />

          <Link to="/services" className="button primary">See What We Offer</Link>
        </section>
        
        <Testimonial />

        <section>
          <h1>How Can We Help?</h1>

          <h2>Residential or Commercial, we're here for you!</h2>

          <p>
            With our vast array of services, we are able to help with many projects; whether it's new Double Glazing for your home, or whether you need a fresh lick of paint for the office.
          </p>

          <img src={data.call.publicURL} alt="Generic contact us illustration"/>

          <Link to="/services" className="button secondary">See How We Fit In</Link>
        </section>
        
      </Layout>
    </>
  )
}

export default Home;
